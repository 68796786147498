import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "navigation-patterns-show-how-the-ui-shell-components-can-be-used-individually-or-combined-to-solve-navigation-for-common-information-structures"
    }}>{`Navigation patterns show how the UI Shell components can be used individually or combined to solve navigation for common information structures.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Configurations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Navigation</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Grouping information</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Coming soon</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "terms"
    }}>{`Terms`}</h2>
    <p>{`These sets of terms are used to describe the position in hierarchy and the importance to a user’s task. The top level global navigation may not be the primary navigation for most of your user’s tasks.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Position in Hierarchy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Universal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest level. The hub that connects other sites with their own Global navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Global`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The level below universal. More focused information. Switch between applications. See navigating `}<a parentName="td" {...{
              "href": "#between-offerings"
            }}>{`between offerings`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Local`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The lowest level. See navigating `}<a parentName="td" {...{
              "href": "#within-an-offering"
            }}>{`within an offering`}</a></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Importance to a user’s task`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates your user to the main sections in your UI. This may not always be top level in hierarchy. Typically seen as the global navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates between the areas of a section. Typically seen as the local navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tertiary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The third most important nav to your user’s task. This navigation changes subsets of information of a page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Utility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A collection of utilities your user expects to be accessed anywhere in your UI. These can be links in the header or footer that aren’t tied to a primary user’s journey.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "configurations"
    }}>{`Configurations`}</h2>
    <p>{`The UI shell is designed to be configurable. A product or platform can choose which shell components and configurations to use, based on their user and information requirements.`}</p>
    <h3 {...{
      "id": "header-only"
    }}>{`Header only`}</h3>
    <p>{`The UI Shell header can be used as standalone navigation for your UI when a small number of main sections do not require a secondary navigation. The header provides a place for a site title, navigation links and dropdowns, and header icons.`}</p>
    <p>{`This configuration gives more horizontal space for the page’s content, but limits the space for navigation items in the header. This configuration also does not lend itself to sub-menus that can be persistently open as they can overlay and interfere with the page’s content. Consider using a left panel if a sub-menu needs to remain open.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.78571428571428%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACWElEQVQ4y62Ra0vjQBSGC0L8ef6f/Q01oOCuFS9Y6C7qh4X1gwsLy4LWy9qmWrXqpJdJYm2bNs11pmba9OxJqumC+3EPPLwz5z3zckgyGazaQ3WJdp5yfdvOttptufpI5FtC5Bu1nlIlqP9Ezd6qjVyt0VzKvNX2ylru+/5X2F3bgML6FnzZ2IW9nQPY28zD/lYedRc+57bfUVjfgfzHTTgsHMD2yqdcGvh0dJ5tHZ5A89sxt35WxPiiLsLzGCIEEp4RwU6J4EhQJMI+VhPiu18kHKrP8HL6mE0D3WJNFuUmuMU7EZw9AFzp75hUDIiQiWKAQMKy8dYTQP14Rp4HntzJotSE9o9LMfh1BVOFpgAyKVN4KWkwLs8I8SxewbuAuouzrXng6ILIcNMG+/gm2XBa0VIAGSsaBuogynPlqCHqqKQLaHg4S+eBUKZy3AQFTRyCUguhM3C76JKC+E0hvKAwflV+PlPsC1AdmF7+tSGnXRmcEEaaKV50E0YI13rAaQ9YqwMMlWtm7KP2gSE8OSezAvxJ7M0DLddZFtMI3MAPfc4iy7Ej/fkp0tpG1B2YkeXaUTDikYeex4LI58iIJXg8CAEL3y6ngZ7nrcZNzhgIIRJ1HAdcRIQhcM7BHtrAggDvM9999QPfj5+C57qr87/suh8w1ERqvu+Tfr9PNE0jhmGQTqdDut0uaTQaRNd1Ypom6fV6RFXVBPTiN2ackQYOBoMFZBGRYizLkiilUr1el66vr6XhcCgxxiRFURIwSKpUKsn5/v4+9hdxiYUkzLbtzP8q/EyZP/NC7ZQKp2BiAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a header as the global navigation",
        "title": "Example of a header as the global navigation",
        "src": "/static/a63da32f23b8ad0a02deac27e801fdc5/fb070/header-only-example.png",
        "srcSet": ["/static/a63da32f23b8ad0a02deac27e801fdc5/d6747/header-only-example.png 288w", "/static/a63da32f23b8ad0a02deac27e801fdc5/09548/header-only-example.png 576w", "/static/a63da32f23b8ad0a02deac27e801fdc5/fb070/header-only-example.png 1152w", "/static/a63da32f23b8ad0a02deac27e801fdc5/fb104/header-only-example.png 1728w", "/static/a63da32f23b8ad0a02deac27e801fdc5/8fefe/header-only-example.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">The IBM Design website using header-only navigation.</Caption>
    <h3 {...{
      "id": "header-and-left-panel"
    }}>{`Header and left panel`}</h3>
    <p>{`The left panel allows for more navigational items and another level of hierarchy when paired with header navigation items. This arrangement means sub-menus can remain open without overlaying or interfering with the page’s content. Compared to the navigation items in the header, the left nav is more forgiving for translating titles as the number of characters in one title does not shift the position of the rest.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.78571428571428%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACI0lEQVQ4y62RT4vaQBjGRQXjqdWqXejeS6GfpdtepGD8U4VNv8R+A81hTwr16PagVnpUFFaP3gqFoqwQ/6xKE9MYNdFofPvOYKJQKRT6wpNnZjLzy7xPHA6sH9+/XQnDfk4UpfRypfOiPOelXyovoqjj/C9K457cTFlcOazK5z/lGo0G3N19hutrDiJsFNholHokwkI0FoNYLH5WLO7huI/obM4GPj6O04Alz2aax+MxcOlfpKEAlbaB8/mcJ0Bd142Li+fw5KkfXr56DaFQCF5cXoLX6wWn0wkul+ucjIPzNlDXNQo0TdNotVrQbLbg/r4J1WoVarUa1Ot1IJFYIvMTGe12m/gR+FOcUeB6vTZ2ux1sNhtYqCqQMan9fm+7NT4pgzy22+0RKEoyBSLIUBQFNE2jO2VZhsFgAMPhEEajEfT7farxeEzXyHsLiOMjEG/GW19bLBYUiO0DgU8mE5AkCabTqS0CImuYPY2JHCT/4SRDPXMAblRVNbEtk0RKZBgGdWzfxLaoW+8O2hxumLGBq9Xq9jSUTqcDpVIZvlS+QqlcgWKpBMVi8Q+Vy2UoFAogiiLJ8NYGLpfLG4QqeJsH5AmZTEZwud1CMBgU/P5ngs/nO6tAICDg8YdsNqvguRsbiAG7u92uF6EMZsLk83nm3ds3TOpDlImx75lEIs7E4wkmmUxSpVIpnMcZjuOYcDjMVCoVLwLdFNbr9Rz/qxDq+A0eMWmmfNDUNQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of different Shell configurations.",
        "title": "Example of different Shell configurations.",
        "src": "/static/3bd5d29a3dbc9c58c2c131d00fd21155/fb070/header-leftpanel-example.png",
        "srcSet": ["/static/3bd5d29a3dbc9c58c2c131d00fd21155/d6747/header-leftpanel-example.png 288w", "/static/3bd5d29a3dbc9c58c2c131d00fd21155/09548/header-leftpanel-example.png 576w", "/static/3bd5d29a3dbc9c58c2c131d00fd21155/fb070/header-leftpanel-example.png 1152w", "/static/3bd5d29a3dbc9c58c2c131d00fd21155/fb104/header-leftpanel-example.png 1728w", "/static/3bd5d29a3dbc9c58c2c131d00fd21155/8fefe/header-leftpanel-example.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of the left panel as the global navigation.</Caption>
    <h2 {...{
      "id": "navigation"
    }}>{`Navigation`}</h2>
    <h3 {...{
      "id": "between-offerings"
    }}>{`Between offerings`}</h3>
    <p>{`Navigating between offerings or sections is made easy through the app switcher. The items and order of a global navigation should remain unchanged when switching between offerings.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.80357142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB3UlEQVQoz2NgAIJXM/8z3Or8z3h58n/GO9P+M76b95+hcIItA6lAWVGFgWFzyw/GMzteCpw7/EDwwsGn/GcP3Bc4seWpAEiBo5MVW2pqajsQT4uJiZkcHR0NxyB+ZETkZA01rUkqSuozdbWMzMCmrum/wbR/xSOh/Zsv85/c9YD/8I5LQsc3PxH9f/k/w95DB7mfPHn68z8QfPr06f+HDx/+f/z4EUx//vL5/4vnL/8bGBj+FxUV/a+lrpcJd+rMrq0s+7buFTiw66jgnu1HBLes3y2YnhDJcvLSQ85Pn78+/fPnz/8fP378/P79+y8YBoF3bz/8MjYy/SkuJvEf6MIEFP+v3Xla6ujJs3LIYg/v3+MG6nsJcuG3b9/+glwHcimI/vLly/9Xr17909fT/yMqIvpfVlYuGqzpyf//EFeuPc+99/BZHhAbqJ8RRD99+hRk4DOogb+Ahv35/PnzHxD99evXP0ADf+vq6v4SEhL6LyUlFQl3yX+ooTD269evGGAG/v79++t/HABoyX99ff3/wsLC/6WlpeNRDJu97gwwEk6D2UAXgOkHDx6wA12yBOjK7UCxzW/fvt3y7t27LSAaGDFbgBZuAbpwE9CFu4EGOuBNU8iuJhYAXcoAAKH2Sjoctd4rAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Diagram and image of the switcher being used to navigate between offerings.",
        "title": "Diagram and image of the switcher being used to navigate between offerings.",
        "src": "/static/7b2d1bf052dcfc1a252370267e6d3aa2/fb070/between-offerings.png",
        "srcSet": ["/static/7b2d1bf052dcfc1a252370267e6d3aa2/d6747/between-offerings.png 288w", "/static/7b2d1bf052dcfc1a252370267e6d3aa2/09548/between-offerings.png 576w", "/static/7b2d1bf052dcfc1a252370267e6d3aa2/fb070/between-offerings.png 1152w", "/static/7b2d1bf052dcfc1a252370267e6d3aa2/fb104/between-offerings.png 1728w", "/static/7b2d1bf052dcfc1a252370267e6d3aa2/8fefe/between-offerings.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">The switcher being used to navigate between offerings.</Caption>
    <h4 {...{
      "id": "state-memory-persistent-data-and-ui-state"
    }}>{`State memory: persistent data and UI state`}</h4>
    <p>{`The app switcher (9 dot icon) makes it easy to pivot between different offerings in your UI. Consider maintaining or restoring the state of a page if your users pivot between different areas to complete tasks. Maintain states and filters brings the user back to where they were if they have gone through drill downs or welcome screens. An obvious way to drill back up the UI is crucial when bypassing drill down navigation in this way.`}</p>
    <p>{`Use the Shell’s menu items to track the essential state elements in the URL and return the user to that URL automatically when they return.`}</p>
    <h3 {...{
      "id": "within-an-offering"
    }}>{`Within an offering`}</h3>
    <p>{`The primary, or local, navigation takes users between areas of an offering. Generally, these ‘areas’ are collections of pages that should be grouped together so a user can undertake an end to end workflow without changing area.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.80357142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1ElEQVQoz5WSXUvbUBjH48RZKcOZtBelpTDBy75c+AF2LfoBagMiaz+L166wIYJ4MbzZpqLRUF+JVGttZXoh6sZGJkZtpW2aNEmbtD4+p9VOmAr+4fA8HMjv/HKeQwEA9Vj4mX3qpXG73c1mJyV07B8muw6OUp17P7Zty+tfO8j+0MDg62gkOhaJRD+xYTYWDodbi2XZWCgUinm93o8ul2vC4/H0t8jfpjZtwsJJT2pF7E7wR0yC+/VWj8OrRPwnffZXsuAGoCSrIMsylEqlRlVVFbLZLASDQXA6nYB2oy1gfFq0C1zGIcT36I3ltIOf32LmPqffZATZoajaZb1ugaFXq4ZumEYFl2GYJMVi0fT7/RWapgkw1AKOf5hqX+KPe5OJ476ZsV3byuSV7R212naavqbx+2u8Z9BUo07sFEVpWJbLZcjlcjc+n6/OMAwBDjdgf/LNoYx/T3WtbSftpL8f1PnZpd2smhcEqOu6ibAa/mqNVE3Tagi0EGj+Z6hU/k0aakBJv4ttpJcuzu2WZWnwRPAQCAQCcGc4Qj20mZzNUOtb6eYBitKooih2oskXvC4e9xbz+TxXKBQ4UnEwnCRJHBouoOEqAt8/+6aeep/PBU2pW7E8Q5394s0PAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of left nav navigating within an offering.",
        "title": "Example of left nav navigating within an offering.",
        "src": "/static/8fbe6e0b079f95c1b79a8b1350dc9787/fb070/within-offering.png",
        "srcSet": ["/static/8fbe6e0b079f95c1b79a8b1350dc9787/d6747/within-offering.png 288w", "/static/8fbe6e0b079f95c1b79a8b1350dc9787/09548/within-offering.png 576w", "/static/8fbe6e0b079f95c1b79a8b1350dc9787/fb070/within-offering.png 1152w", "/static/8fbe6e0b079f95c1b79a8b1350dc9787/fb104/within-offering.png 1728w", "/static/8fbe6e0b079f95c1b79a8b1350dc9787/8fefe/within-offering.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">The switcher being used to navigate within an offering.</Caption>
    <p>{`Match an offering’s navigation with neighboring offerings. Following a similar application structure and navigation as the rest of the platform will minimize transitional volatility and your users will spend less time getting reoriented. Your users will be able to quickly move between screens and states in your product and their expectations for the platform’s capability will increase.`}</p>
    <h4 {...{
      "id": "drill-down-levels--breadcrumbs"
    }}>{`Drill down levels & breadcrumbs`}</h4>
    <p>{`Breadcrumbs let your user see where they are in the hierarchy of the application and gives users a way to navigate back up.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.80357142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAB3UlEQVQoz5WRz0sbQRTHNyXEaCTShBVCQm495sfBP6CHFvWkohXXTREP+Ut6qOcsCPZQWpC29GLUlQRjNI1olECIHqRQLCu4LYkku8lms5vN2tc3aRII1INfGN7wYD7zeTMUAFD/y8cP76nHxuv1/tvkcjlboVBwFItFez6fH0mlUjbSn56atEWj0be41pml19ziAsstvWI5lmW5SCTCMQzD+f3+mMfj2fD5fBN9ciwWs+7xe/ZsNjuUTqdHk8mknfQPv504bm/FFk4BkqRAuSxB5U4CWa6BoihQKpUgHA4DTdOAdqt94Pz8giWRTIxlMkdPEebcised0ZVF6/nlzXBdUUXTNKHV0lu6rhm6rhuaphkkkiQZwWBQd7lcBMj0gZNTLy0727wjfXDknJ2bedLr3wg/HXiuRAybzeZ9rVaDer0OpDYaDTQu/wkEAvdut5sAlwce9DTxnT7b/zH+7suatfdRoigS4K8u0ECYiaOapKqqaiKwjUBjwLB3OL55QWf4K0+3Z+kB2+22Cg8EL4FQKARdw5UO6PPX607d2v7tOz8WnpH9ZUHoAAVBGEKTTbRMoNVupVLhq9UqT6osyzxeyKPhDhqmEPh8YGTtE1BzFFAv3gwPmD8maEr9BbKcPRH9AxUOAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Breadcrumbs being used to navigate up a level.",
        "title": "Breadcrumbs being used to navigate up a level.",
        "src": "/static/e2284fe46f0152ac4c26e0606b3017c3/fb070/drill-down.png",
        "srcSet": ["/static/e2284fe46f0152ac4c26e0606b3017c3/d6747/drill-down.png 288w", "/static/e2284fe46f0152ac4c26e0606b3017c3/09548/drill-down.png 576w", "/static/e2284fe46f0152ac4c26e0606b3017c3/fb070/drill-down.png 1152w", "/static/e2284fe46f0152ac4c26e0606b3017c3/fb104/drill-down.png 1728w", "/static/e2284fe46f0152ac4c26e0606b3017c3/8fefe/drill-down.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Breadcrumbs being used to navigate up a level.</Caption>
    <p>{`In many cases users have a need to drill down into a new context (eg from an overview page to a particular project, device or asset view. For this kind of interaction of up or downwards navigation instead of lateral navigation a drill down pattern should be used.`}</p>
    <p>{`A drill down can be triggered from any interactive element in an application, and will generally open a new page focussed purely on the object that was selected. This new page will then include a breadcrumb of the path back to the root level above the title.`}</p>
    <h2 {...{
      "id": "grouping-information"
    }}>{`Grouping information`}</h2>
    <h3 {...{
      "id": "organization-schemes"
    }}>{`Organization Schemes`}</h3>
    <p>{`Your UI may need different schemes for organizing content in different parts of your UI. Create logical groups that align to your user’s goals and helps the user understand the scope of the content.`}</p>
    <p><strong parentName="p">{`Recently used:`}</strong>{`
Ordering a navigation by recently used helps users are frequently switching between areas. This loses any logical grouping and should be used as an alternative way to navigate and not the primary.`}</p>
    <p><strong parentName="p">{`Customized:`}</strong>{`
A user customized navigation lets the user personalize the UI for their own needs.`}</p>
    <p><strong parentName="p">{`Audience:`}</strong>{`
Cautions. Tasks may overlap personas - decreasing discoverability.`}</p>
    <p><strong parentName="p">{`Alphabetically:`}</strong>{` Organizing your navigation alphabetically is only successful if your user knows what they are looking for and how the item is labeled. Alphabetical navigation decreases discoverability in cases where your user is looking for a synonym of an item (e.g. “pop-up, modal, lightbox”).`}</p>
    <p><strong parentName="p">{`Organization:`}</strong>{`
Your navigation may be composed of multiple applications, resources, and platforms working together. The navigation should reflect an appropriate domain model and not your org chart.`}</p>
    <p>{`When planning your product’s UI, put the emphasis on the tasks the user has to complete rather than business or technical limitations. Forcing the user to learn a new mental model for your product increases the time to productivity and creates a disjointed experience between your product and the platform.`}</p>
    <h3 {...{
      "id": "unbound-content"
    }}>{`Unbound content`}</h3>
    <p>{`Don’t place unbounded content in the shell side navigation. Usability drops rapidly as the number of items within the shell get too high. For this reason, do not place content that has no upper limit (such as created by users) within the shell’s side navigation. Instead make use of drill down patterns.`}</p>
    <h2 {...{
      "id": "coming-soon"
    }}>{`Coming soon`}</h2>
    <ul>
      <li parentName="ul">{`Considerations for translating navigation for right to left languages.`}</li>
      <li parentName="ul">{`Hierarchy and placement of information`}</li>
      <li parentName="ul">{`More content organization schemes: task`}</li>
      <li parentName="ul">{`Common utilities in the header: Search, notifications, helps, user profile`}</li>
      <li parentName="ul">{`Logged in vs. logged out experience`}</li>
      <li parentName="ul">{`Accessibility consideration: DOM order and tab sequence`}</li>
    </ul>
    <h4 {...{
      "id": "references"
    }}>{`References:`}</h4>
    <ul>
      <li parentName="ul">{`David R. Danielson, `}<a parentName="li" {...{
          "href": "https://www.researchgate.net/publication/240859594_Transitional_volatility_in_web_navigation"
        }}><em parentName="a">{`Transitional Volatility in Web Navigation`}</em></a>{` (2003)`}</li>
      <li parentName="ul">{`Susan Farrell, `}<a parentName="li" {...{
          "href": "https://www.nngroup.com/articles/utility-navigation/"
        }}><em parentName="a">{`Utility Navigation: What It Is and How to Design It`}</em></a>{` (2015)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      